<template>
  <div class="DeveloperUserProject">
    <ul>
      <li
        v-for="(application, index) in currentDeveloperProject.applications"
        :key="index"
      >
        <application-item
          :application="application"
          :applicationindex="index"
          @approveApplication="approveApplication"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApplicationItem from './DeveloperUserApplication';

export default {
  name: 'DeveloperUserProject',
  components: {
    ApplicationItem,
  },
  data() {
    return {
      busy: false,
    };
  },
  computed: {
    ...mapGetters(['currentDeveloperProject', 'currentDeveloperUser']),
  },
  methods: {
    approveApplication(applicationInfo) {
      this.$store.dispatch(
        'markDeveloperApplicationAsReviewed',
        applicationInfo
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/developer/developer-user-project/DeveloperUserProject.scss';
</style>
