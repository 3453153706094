<template>
  <div class="DeveloperUserApplication">
    <div class="DeveloperUserApplication__header">
      <div class="DeveloperUserApplication__title">
        <img
          :src="applicationInfo.applicationIconUrl"
          class="DeveloperUserApplication__icon"
        />
        <div>{{ applicationInfo.applicationName }}</div>
      </div>
      <div
        v-if="applicationInfo.applicationReviewed"
        class="DeveloperUserApplication__reviewed"
      >
        Reviewed
      </div>
      <div v-else class="DeveloperUserApplication__in-review">In Review</div>
    </div>
    <li
      v-for="(value, key) in applicationDetails"
      :key="key"
      class="DeveloperUserApplication__property"
    >
      <span>{{ sentenceCase(key) }}:</span>
      <span>{{ value }}</span>
    </li>
    <button
      v-if="!applicationInfo.applicationReviewed"
      class="button button-success"
      @click="markDeveloperApplicationAsReviewed()"
    >
      Accept Application
    </button>
  </div>
</template>

<script>
export default {
  name: 'DeveloperUserApplication',
  props: {
    application: {
      type: Object,
      required: true,
    },
    applicationindex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    applicationInfo() {
      switch (this.applicationType) {
        case 'ios':
          return this.application.ios;
        case 'android':
          return this.application.android;
        case 'web':
          return this.application.web;
        default:
          return '';
      }
    },
    applicationDetails() {
      switch (this.applicationType) {
        case 'ios':
          return {
            applicationClientId: this.application.ios.applicationClientId,
            applicationBundleId: this.application.ios.applicationBundleId,
            applicationRedirectUri: this.application.ios.applicationRedirectUri,
          };
        case 'android':
          return {
            applicationClientId: this.application.android.applicationClientId,
            applicationPackageName:
              this.application.android.applicationPackageName,
            applicationSignatureSha1: this.application.android
              .applicationSignaturesSha1
              ? this.application.android.applicationSignaturesSha1.join(', ')
              : this.application.android.applicationSignatureSha1,
          };
        case 'web':
          return {
            applicationClientId: this.application.web.applicationClientId,
            applicationWebsiteUrl: this.application.web.applicationWebsiteUrl,
          };
        default:
          return '';
      }
    },
    applicationType() {
      return Object.keys(this.application)[0];
    },
  },
  methods: {
    markDeveloperApplicationAsReviewed() {
      this.$emit('approveApplication', this.applicationInfo);
    },
    sentenceCase(text) {
      var replace = text.replace(/([A-Z])/g, ' $1');
      return replace.charAt(0).toUpperCase() + replace.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/developer/developer-user-project/DeveloperUserApplication.scss';
</style>
